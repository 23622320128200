<template>
    <div>

        <ol class="step-list">
            <li v-for="(step, index) in insturctions" :key="index">{{ $t(step) }}</li>
        </ol>

        <div class="screenshot">
            <img src="../assets/screen-4.png" :alt="$t('faq.playberry.step4.instruction1')" :width="500" class='image'>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            insturctions: ['faq.playberry.step4.instruction1', 'faq.playberry.step4.instruction2', 'faq.playberry.step4.instruction3']
        }
    }
}
</script>

<style scoped>
p,
ol {
    font-family: 'Wix Madefor Display', sans-serif;
    color: white;
    margin: 0;
}

.text {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    color: #FFFFFF99;
    margin-bottom: 3px;
}

.step-list {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    color: #FFFFFF99;
    padding: 0 16px;
    margin-bottom: 32px;
}

.screenshot {
    display: flex;
    justify-content: center;
    padding: 32px 10px 0;
    border-radius: 8px;
    background-color: #141417;
}

@media only screen and (max-width: 500px) {
    .image{
        width: 100%;
    }
}
</style>
